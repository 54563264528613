import React from 'react'
import g from 'glamorous'
import Panoramique from '../components/Panoramique'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

const ContactPage = () => (
  <Layout>
    <Helmet>
      <title>Contact | Valerie Pasquiou Interiors & Design</title>
      <meta name="referrer" content="origin" />
      <meta
        name="description"
        content="Get in touch with VP interiors, interior design and architecture agency in Paris and New York City"
      />
    </Helmet>
    <main>
      <Wrapper>
        <Col
          css={{
            gridRowEnd: `span 2`,
          }}
        >
          <H1>Contact</H1>
          <Form
            action="https://formspree.io/info@vpinteriors.com"
            method="POST"
          >
            <Label for="name">Name</Label>
            <Input type="text" name="name" />
            <Label for="_replyto">Email</Label>
            <Input type="email" name="_replyto" />
            <Label for="phone">Phone</Label>
            <Input type="phone" name="phone" />
            <Label for="message">Message</Label>
            <Textarea type="text" name="message" />
            <InputButton type="submit" value="Send" />
            <input
              type="hidden"
              name="_subject"
              value="New submission : Contact Form Website"
              css={{ display: `none` }}
            />
            <input
              type="hidden"
              name="_cc"
              value="henri@createursdinterieur.com"
            />
            <Input type="text" name="_gotcha" css={{ display: `none` }} />
          </Form>
        </Col>

        <Col>
          <Address>
            <strong>NEW YORK STUDIO</strong>
            <br />
            39 Walker St #3F
            <br />
            NY 10013
            <br />T +1 212 227 3983
            <br />
            info@vpinteriors.com
          </Address>
        </Col>
        <Col>
          <Address>
            <strong>PARIS STUDIO</strong>
            <br />
            29 rue du Faubourg-Poissonnière
            <br />
            75009 Paris
            <br />T +33 7 64 10 17 40
            <br />
            valerie@vpinteriors.com
          </Address>
        </Col>

        <ImageWrapper>
          <Panoramique />
        </ImageWrapper>
      </Wrapper>
    </main>
  </Layout>
)

export default ContactPage

const Wrapper = g.section({
  display: `grid`,
  gridTemplateColumns: `33% 33% 33%`,
  gridGap: `1.5em`,
  rowGap: `8px`,
  marginTop: `85px`,
  '@media(max-width: 768px)': {
    width: `100%`,
    display: `block`,
    marginTop: `10px`,
  },
})

const Col = g.div({})

const ImageWrapper = g.div({
  gridColumn: `2 / span 2`,
  '@media(max-width: 768px)': {
    marginTop: `20px`,
  },
})

const H1 = g.h1({
  fontSize: `2.5em`,
  marginTop: `.1em`,
  padding: `0 15px .25em`,
  display: `inline-block`,
  marginBottom: `.4em`,
  display: `none`,
  '@media only screen and (max-width : 768px)': {
    display: `block`,
    fontSize: `2em`,
  },
})

const Address = g.div({
  display: `block`,
  fontSize: `.9em`,
  lineHeight: `1.65em`,
  textAlign: `center`,
  paddingTop: `20px`,
  paddingBottom: `30px`,
})

const Form = g.form({
  padding: `0 15px`,
})

const Label = g.label({
  fontSize: `.85em`,
  display: `block`,
  marginBottom: `0em`,
  letterSpacing: `.015em`,
})

const Input = g.input({
  display: `block`,
  width: `100%`,
  marginBottom: `.9em`,
  borderRadius: `3px`,
  border: `1px solid grey`,
  padding: `0`,
  ':focus': {
    outline: `none`,
  },
  ':hover': {
    border: `1px solid rgba(73,105,126,0.5)`,
    boxShadow: `0 1px 8px rgba(0,0,0,.26)`,
    backgroundColor: `white`,
    color: `#3f3f3f`,
    transition: `all 0.1s ease-in-out`,
    outline: `none`,
  },
})

const InputButton = g.input({
  display: `block`,
  marginBottom: `.9em`,
  borderRadius: `3px`,
  border: `1px solid grey`,
  padding: `0`,
  backgroundColor: `#1f1f1f`,
  color: `white`,
  width: `120px`,
  padding: `.4em .5em`,
  borderRadius: `4px`,
  marginTop: `.5em`,
  float: `right`,
  ':focus': {
    outline: `none`,
  },
  ':hover': {
    border: `1px solid rgba(73,105,126,0.5)`,
    boxShadow: `0 1px 8px rgba(0,0,0,.26)`,
    backgroundColor: `white`,
    color: `#3f3f3f`,
    transition: `all 0.1s ease-in-out`,
    outline: `none`,
  },
  '@media(max-width: 768px)': {
    float: `none`,
  },
})

const Textarea = g.textarea({
  display: `block`,
  width: `100%`,
  marginBottom: `.75em`,
  resize: `none`,
  height: `185px`,
  padding: `0`,
  borderRadius: `3px`,
  border: `1px solid grey`,
  ':focus': {
    outline: `none`,
  },
})
