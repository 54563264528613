import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import g from 'glamorous'
import { css } from 'glamor'
import Img from 'gatsby-image'

export default () => (
  <StaticQuery
    query={graphql`
      query panoramiquequery {
        file(relativePath: { eq: "contact.jpg" }) {
          childImageSharp {
            resize(width: 1000, height: 500) {
              src
            }
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Div>
        <Img fluid={data.file.childImageSharp.fluid} {...cssImage} alt="" />
      </Div>
    )}
  />
)

const Div = g.div({
  padding: `0 45px`,
  '@media(max-width: 768px)': {
    padding: `0`,
  },
})

const cssImage = css({
  width: `100%`,
})
